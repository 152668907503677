/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
.header-top {
  background: #2b5876; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4e4376,
    #2b5876
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4e4376,
    #2b5876
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: #2b5876; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4e4376,
    #2b5876
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4e4376,
    #2b5876
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  /* position: relative; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.landing-grid {
	background-image: linear-gradient(125deg,#E4FFCD,#6DD5FA,#2980B9,#E4FFCD);  background-size: 400%;
  animation: bganimation 5s infinite;
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.avatar-img {
  height: 250px;
  padding-top: 5em;


}
.banner-text {
  background-color: black;
  opacity: 0.8;
  width: 80%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  /* margin-top: -5em; */
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
  /* font-family: 'Crimson Text', serif; */
}

/* .mdl-layout__drawer-button{
  display: none;
}

@media screen and (max-width:1020px){
  .mdl-layout__drawer-button{
    display: inline;
  }
} */

.banner-text hr {
  border-top: 1px solid white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 4em;
}

.contact-body {
  margin: auto;
  background: gray;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
  /* padding-top: 5em; */
}
.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  border: 2px soild red;
  margin-bottom: 20px;
  margin-top: 20px;

} 

.contact-grid h2 {
  font-family: "Source Serif Pro", serif;
}

.contact-grid p {
  font-family: "Source Serif Pro", serif;
}

/* .contact-grid {
  text-align: center;
  justify-content: center;
  width: auto;
  height: 76%;
  background: white;
  color: black;
  border: 2px soild red;
  margin-bottom: 20px;
  margin-left: 199px;
  margin-right: 199px; 
} */


.sent {
  margin: auto;
  /* background: gray; */
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 19em;
  /* padding-top: 5em; */
  /* padding-left: 46em; */
  text-align: center;
  font-weight: 92;
  font-size: 20px;
}


.mdl-tabs__tab-bar {
  margin-top: 37px;
}


body {
  -webkit-overflow-scrolling: touch;
}